<template>
  <!-- mobile version 2 -->
  <div class="photos-list">
    <Splide
      ref="splide"
      :options="splideOptions"
      @splide:active="onSlideChange"
    >
      <!-- <div style="position: relative"> -->
      <SplideSlide v-for="(photo, index) in photos" :key="index">
        <photo-item
          :photo="photo"
          ref="photoComponentsList"
          :canDownload="canDownload"
        />
      </SplideSlide>
      <!-- </div> -->
    </Splide>

    <div class="list-controls" v-if="showButtons">
      <mem-button
        btn-type="secondary-dark"
        v-if="currentPhoto.downloadedItem"
        @click="sharePhoto"
        >{{ $t("save_to_photos") }}</mem-button
      >
      <template v-else>
        <mem-button btn-type="tertiary-dark" v-if="showLoadProgress">
          <span>{{ currentPhoto.loaded }}</span>
        </mem-button>
        <mem-button btn-type="tertiary-dark" @click="download" v-else>
          <span>{{ $t("download_to_save") }}</span>
        </mem-button>
      </template>
    </div>
  </div>
</template>
<script>
import { find } from "lodash";
import { mapMutations } from "vuex";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  components: {
    Splide,
    SplideSlide,
    "photo-item": () => import("./PhotosMobileListItem.vue"),
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    splideOptions: {
      arrows: false,
      pagination: false,
      perPage: 1,
      perMove: 1,
      // focus: "center",
      trimSpace: false,
      autoWidth: true,
      autoHeight: true,
      gap: 20,
      padding: { left: 40 },
    },
    splideIndex: 0,
  }),
  props: ["photos", "canDownload"],
  computed: {
    currentPhoto() {
      let photoObj = find(this.photos, {
        athlete_image_id: this.photos[this.splideIndex].athlete_image_id,
      });
      return photoObj || this.photos[0];
    },
    downloadLink() {
      if (this.currentPhoto.original) return this.currentPhoto.original;
      return this.currentPhoto.watermarked_preview;
    },
    showLoadProgress() {
      let { loaded } = this.currentPhoto;
      return loaded && loaded !== 100;
    },
    showButtons() {
      return false;
    },
  },
  methods: {
    ...mapMutations(["updatePhotosListItem"]),
    onSlideChange() {
      let { splide } = this.$refs;

      this.splideIndex = splide.index;
    },
    async download() {
      // Step 1: start the fetch and obtain a reader
      let response = await fetch(this.downloadLink);
      const reader = response.body.getReader();

      // Step 2: get total length
      const contentLength = +response.headers.get("Content-Length");
      // console.log(contentLength, "Content length");

      // Step 3: read the data
      let receivedLength = 0; // received that many bytes at the moment
      let chunks = []; // array of received binary chunks (comprises the body)
      let loading = true;
      while (loading) {
        const { done, value } = await reader.read();

        if (done) {
          loading = false;
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        this.updatePhotosListItem({
          photoId: this.currentPhoto.athlete_image_id,
          payload: {
            loaded: Math.round((receivedLength / contentLength) * 100),
          },
        });

        // console.log(`Received ${receivedLength} of ${contentLength}`);
      }

      //
      let blob = new Blob(chunks);
      this.updatePhotosListItem({
        photoId: this.currentPhoto.athlete_image_id,
        payload: {
          downloadedItem: new File(
            [blob],
            `result_${this.currentPhoto.athlete_image_id}.png`,
            {
              type: blob.type,
            }
          ),
        },
      });

      // this.downloadedItem = URL.createObjectURL(blob);
      // this.downloadedItem = new File([blob], `result_${this.photoId}.png`, {
      //   type: blob.type,
      // });
    },
    async sharePhoto() {
      console.log(this.currentPhoto, "SHARE");
      try {
        let res = await navigator.share({
          files: [this.currentPhoto.downloadedItem],
        });
        console.log(res, "Share result");
      } catch (error) {
        console.log(error, "Share error");
      }
    },
  },
  mounted() {
    // console.log(this.currentPhoto, "MO");
  },
};
</script>
<style lang="scss" scoped>
.photos-list {
  padding-bottom: 40px;
  .list-controls {
    margin-top: 16px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
}
</style>